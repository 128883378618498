<template>
  <div class="mainpage">
    <div class="head">
      <div class="head-title" v-if="type == 'edit'">
        {{ formData.type | logformat }}
      </div>
      <div class="head-title" v-else>{{ titleType | formats }}</div>
      <!-- <el-button type="primary" @click="submitbt">返回</el-button> -->
      <div class="retutnbt" @click="toback">返回</div>
    </div>
    <div class="line"></div>
    <div class="topcontain">
      <div class="text text2">{{ iteminfo.plan_name }}</div>
      <div class="text">实习形式：{{ iteminfo.type | typeformat }}</div>
      <div class="text">
        实习时间：{{ iteminfo.start_date | dataformat }} ~
        {{ iteminfo.end_date | dataformat }}
      </div>
    </div>
    <div class="maincontain">
      <el-form
        class="elform"
        ref="form"
        :model="formData"
        :rules="formRules"
        label-width="100px"
      >
        <el-form-item class="elinput" prop="title" label="标题:">
          <el-input v-model="formData.title" placeholder="请输入"></el-input>
        </el-form-item>
        <el-form-item class="elinput" prop="timeslot" label="总结时间:">
          <el-date-picker
            v-if="formData.type == 0"
            v-model="formData.timeslot"
            type="date"
            placeholder="选择日期"
            @change="changeTime"
          ></el-date-picker>
          <el-date-picker
            v-else
            v-model="formData.timeslot"
            type="daterange"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            @change="changeTime"
          ></el-date-picker>
        </el-form-item>
        <el-form-item class="elinput2" prop="content" label="内容:">
          <div class="text-editor">
            <Tinymce
              ref="editor"
              v-model="formData.content"
              style="height: auto; border-radius: 22px"
              @setInput="getValue"
            ></Tinymce>
          </div>
        </el-form-item>
        <el-form-item class="elinput" label="附件:">
          <UploadFiles
            :module="'practice_student_report,url'"
            :cloudType="'practiceStudentReport/url'"
            :files="fileList"
            @setUrl="getUrl"
          />
        </el-form-item>
      </el-form>
      <div class="bts">
        <el-button type="info" @click="toback">取消</el-button>
        <el-button
          type="primary"
          :loading="isSubmitbt"
          @click="submitbt('form')"
          >提交</el-button
        >
        <!-- <div class="bt" @click="toback()">取消</div>
        <div class="bt bt1" @click="submitbt">提交</div>-->
      </div>
    </div>
  </div>
</template>

<script>
import Tinymce from "@/components/VueTinymce/index.vue";
import dayjs from "dayjs";
import UploadFiles from "@/components/upload-files";
import {
  get_report_deatil,
  getinfopracticestudentreport,
  addpracticestudentreport,
  editpracticestudentreport,
} from "@/api/practicestudentreport";

const typemap = ["自主报名", "集中安排"];
const typeLog = ["编辑日报", "编辑周报", "编辑月报"];
const typeLogAdd = ["新增日报", "新增周报", "新增月报"];

export default {
  name: "mainpage",
  components: {
    Tinymce,
    UploadFiles,
  },
  filters: {
    typeformat(val) {
      return typemap[val];
    },
    dataformat(value) {
      if (!value) return "";
      return dayjs.unix(value).format("YYYY-MM-DD HH:mm:ss");
    },
    logformat(val) {
      return typeLog[val];
    },
    formats(value) {
      return typeLogAdd[value];
    },
  },
  data() {
    return {
      formData: {
        id: "",
        practice_plan_id: "",
        title: "",
        type: "",
        timeslot: null,
        start_date: "",
        end_date: "",
        content: "",
        file: "",
      },
      formRules: {
        title: [
          { required: true, message: "标题不能为空", trigger: "blur" },
          { min: 0, max: 20, message: "长度在20个字符", trigger: "blur" },
        ],
        timeslot: [{ required: true, message: "请选择时间", trigger: "blur" }],
        content: [{ required: true, message: "内容不能为空", trigger: "blur" }],
      },
      fileList: [],
      iteminfo: "",
      isSubmitbt: false, // 提交防抖
    };
  },
  computed: {
    log_id() {
      return this.$route.query.id;
    },
    practice_plan_id() {
      return this.$route.query.practice_plan_id;
    },
    student_id() {
      return this.$route.query.student_id;
    },
    type() {
      return this.$route.query.type;
    },
    titleType() {
      return this.$route.query.titleType;
    },
  },
  created() {
    this.formData.practice_plan_id = this.practice_plan_id;
    this.init_repoet_info();
    if (this.type == "edit") {
      this.initLogInfo();
    } else {
      this.formData.type = this.$route.query.titleType;
    }
  },
  methods: {
    init_repoet_info() {
      const params = {
        practice_plan_id: this.practice_plan_id,
        student_id: this.student_id,
      };
      get_report_deatil(params).then((res) => {
        if (res.code === 0) {
          this.iteminfo = res.data;
        }
      });
    },
    initLogInfo() {
      const params = {
        id: this.log_id,
        practice_plan_id: this.practice_plan_id,
        student_id: this.student_id,
      };
      getinfopracticestudentreport(params).then((res) => {
        if (res.code === 0) {
          const data = res.data;
          this.formData = {
            id: data.id,
            practice_plan_id: data.practice_plan_id,
            title: data.title,
            type: data.type,
            start_date: data.start_date ? data.start_date * 1000 : "",
            end_date: data.end_date ? data.end_date * 1000 : "",
            content: data.content,
            file: JSON.stringify(data.file),
          };

          if (data.type == 0) {
            this.formData.timeslot = this.formData.start_date;
          } else {
            this.formData.timeslot = [
              this.formData.start_date,
              this.formData.end_date,
            ];
          }
          this.fileList = Array.isArray(data.file) ? data.file : [];
        }
      });
    },
    toback() {
      this.$router.push({
        path: "/home/personalcenter/log",
        query: {
          practice_plan_id: this.practice_plan_id,
        },
      });
    },
    changeTime(e) {
      if (this.formData.type == 0) {
        this.formData.start_date = e;
      } else {
        this.formData.start_date = e[0];
        this.formData.end_date = e[1];
      }
    },
    submitbt(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          let obj = JSON.parse(JSON.stringify(this.formData));
          // debugger
          if (obj.type == 1) {
            if (obj.start_date) {
              obj.start_date = dayjs(obj.start_date).unix();
            } else {
              obj.start_date = "";
            }
            if (obj.end_date) {
              obj.end_date = dayjs(obj.end_date).unix();
            } else {
              obj.end_date = "";
            }
          } else {
            if (this.formData.start_date) {
              obj.end_date = obj.start_date = dayjs(obj.start_date).unix();
            } else {
              obj.start_date = "";
              obj.end_date = "";
            }
          }
          // 防抖拦截
          if (this.isSubmitbt) {
            return false;
          }
          this.isSubmitbt = true;

          if (this.type == "edit") {
            this.edit_report(obj);
          } else {
            this.addpracticestudentreport(obj);
          }
        }
      });
    },
    addpracticestudentreport(params) {
      addpracticestudentreport(params)
        .then((response) => {
          this.isSubmitbt = false;
          if (response.code === 0) {
            this.$message({
              message: "提交成功!",
              type: "success",
            });
            this.toback();
          }
        })
        .catch((error) => {
          //console.log(error)
        });
    },
    //
    edit_report(params) {
      editpracticestudentreport(params)
        .then((response) => {
          this.isSubmitbt = false;
          if (response.code === 0) {
            this.$message({
              message: "提交成功!",
              type: "success",
            });
            this.toback();
          }
        })
        .catch((error) => {
          //console.log(error)
        });
    },

    // 获取富文本得值
    getValue(data) {
      this.formData.content = data;
    },
    // 获取文件上传的返回值
    getUrl(data, key) {
      this.formData.file = data;
    },
  },
};
</script>
<style scoped lang="scss">
@media screen and (max-width: 640px) {
  .mainpage {
    background: #fff;

    .head {
      margin-top: 87px;
      display: flex;
      justify-content: space-between;
      align-items: center;

      .head-title {
        font-size: 20px;
        font-family: PingFang SC;
        font-weight: 400;
        color: #3d84ff;
        user-select: none;
        white-space: nowrap;
        margin-left: 40px;
        margin-top: 10px;
      }

      .retutnbt {
        margin-right: 40px;
        width: 64px;
        height: 38px;
        line-height: 38px;
        text-align: center;
        background: #3d84ff;
        border-radius: 4px;
        color: white;
        user-select: none;
        cursor: pointer;
        margin-right: 40px;
        margin-top: 10px;
        font-size: 14px;
      }
    }

    .line {
      margin-top: 10px;
      margin-bottom: 4px;
      border-bottom: 2px solid #cccccc;
    }

    .topcontain {
      background: #fff;
      // height: 100px;
      // margin-top: 20px;
      // margin-bottom: 20px;
      padding: 5px;

      .text {
        font-size: 16px;
        font-weight: 500;
        line-height: 35px;
        color: #222222;
      }

      .text2 {
        font-size: 26px;
        margin-bottom: 20px;
      }
    }

    .maincontain {
      background: #fff;
      border-radius: 4px;
      padding-top: 20px;
      padding-left: 20px;
      padding-right: 20px;
      margin-bottom: 20px;
      padding-bottom: 20px;

      .elform {
        .elinput {
          width: 100%;
        }

        .elinput11 {
          margin-right: 10px;
          width: 200px;
        }

        .elinput2 {
          width: 100%;
        }

        .inputcontain {
          display: flex;
          justify-content: flex-start;
          align-items: center;

          .bt {
            padding: 0px;
            margin: 0px;
            margin-left: 10px;
            width: 88px;
            height: 40px;
            line-height: 40px;
            text-align: center;
            background: #ffffff;
            border: 1px solid #cccccc;
            border-radius: 4px;
            font-size: 18px;
            font-weight: 400;
            color: #a0a0a0;
            user-select: none;
            cursor: pointer;
          }

          .bt1 {
            background: #3d84ff;
            color: #ffffff;
          }
        }

        .file-select-contain {
          display: flex;
          justify-content: flex-start;
          align-items: center;

          .file-selecttip {
            margin-left: 10px;
            padding-left: 10px;
            text-align: left;
            width: 200px;
            height: 40px;
            background: #ffffff;
            border: 1px solid #d8d8d8;
            border-radius: 4px;
          }

          .file-selectbt {
            margin-left: 10px;
            font-size: 15px;
            font-weight: 400;
            color: #ffffff;
            width: 121px;
            height: 40px;
            line-height: 40px;
            padding: 0px;
            text-align: center;
            background: #3d84ff;
            border: 1px solid #3d84ff;
            border-radius: 4px;

            &:active {
              background: #3d84ff;
            }
          }
        }
      }

      .bts {
        margin-top: 40px;
        // margin-right: 100px;
        display: flex;
        justify-content: flex-end;
        align-items: center;

        .bt {
          margin-right: 10px;
          width: 64px;
          height: 38px;
          line-height: 38px;
          text-align: center;
          background: #ffffff;
          border: 1px solid #cccccc;
          border-radius: 4px;
          font-size: 14px;

          font-weight: 400;
          color: #a0a0a0;
          user-select: none;
          cursor: pointer;
        }

        .bt1 {
          background: #3d84ff;
          color: #ffffff;
          border: 1px solid #3d84ff;
        }
      }
    }
  }
}
@media screen and (min-width: 1200px) {
  .mainpage {
    background: #fff;

    .head {
      margin-top: 30px;
      display: flex;
      justify-content: space-between;
      align-items: center;

      .head-title {
        font-size: 20px;
        font-family: PingFang SC;
        font-weight: 400;
        color: #3d84ff;
        user-select: none;
        white-space: nowrap;
        margin-left: 40px;
        margin-top: 10px;
      }

      .retutnbt {
        margin-right: 40px;
        width: 64px;
        height: 38px;
        line-height: 38px;
        text-align: center;
        background: #3d84ff;
        border-radius: 4px;
        color: white;
        user-select: none;
        cursor: pointer;
        margin-right: 40px;
        margin-top: 10px;
        font-size: 14px;
      }
    }

    .line {
      margin-top: 10px;
      margin-bottom: 4px;
      border-bottom: 2px solid #cccccc;
    }

    .topcontain {
      background: #fff;
      height: 100px;
      // margin-top: 20px;
      // margin-bottom: 20px;
      padding: 40px;

      .text {
        font-size: 16px;
        font-weight: 500;
        line-height: 26px;
        color: #222222;
      }

      .text2 {
        font-size: 26px;
        margin-bottom: 20px;
      }
    }

    .maincontain {
      background: #fff;
      border-radius: 4px;
      padding-top: 20px;
      padding-left: 20px;
      padding-right: 20px;
      margin-bottom: 20px;
      padding-bottom: 20px;

      .elform {
        .elinput {
          width: 500px;
        }

        .elinput11 {
          margin-right: 10px;
          width: 200px;
        }

        .elinput2 {
          width: 800px;
        }

        .inputcontain {
          display: flex;
          justify-content: flex-start;
          align-items: center;

          .bt {
            padding: 0px;
            margin: 0px;
            margin-left: 10px;
            width: 88px;
            height: 40px;
            line-height: 40px;
            text-align: center;
            background: #ffffff;
            border: 1px solid #cccccc;
            border-radius: 4px;
            font-size: 18px;
            font-weight: 400;
            color: #a0a0a0;
            user-select: none;
            cursor: pointer;
          }

          .bt1 {
            background: #3d84ff;
            color: #ffffff;
          }
        }

        .file-select-contain {
          display: flex;
          justify-content: flex-start;
          align-items: center;

          .file-selecttip {
            margin-left: 10px;
            padding-left: 10px;
            text-align: left;
            width: 200px;
            height: 40px;
            background: #ffffff;
            border: 1px solid #d8d8d8;
            border-radius: 4px;
          }

          .file-selectbt {
            margin-left: 10px;
            font-size: 15px;
            font-weight: 400;
            color: #ffffff;
            width: 121px;
            height: 40px;
            line-height: 40px;
            padding: 0px;
            text-align: center;
            background: #3d84ff;
            border: 1px solid #3d84ff;
            border-radius: 4px;

            &:active {
              background: #3d84ff;
            }
          }
        }
      }

      .bts {
        margin-top: 40px;
        margin-right: 100px;
        display: flex;
        justify-content: flex-end;
        align-items: center;

        .bt {
          margin-right: 10px;
          width: 64px;
          height: 38px;
          line-height: 38px;
          text-align: center;
          background: #ffffff;
          border: 1px solid #cccccc;
          border-radius: 4px;
          font-size: 14px;

          font-weight: 400;
          color: #a0a0a0;
          user-select: none;
          cursor: pointer;
        }

        .bt1 {
          background: #3d84ff;
          color: #ffffff;
          border: 1px solid #3d84ff;
        }
      }
    }
  }
}
</style>
